import useApiClient from './api-client'
import type {
  FproductReviewTagData,
  FreviewReviewResponse,
  NicedayProductListData,
  ProductDetailData,
  NicedayProductAddUpgData,
  FproductMaxCrossDaysRespData,
  FproductRemarkV2Data,
  FpromoListByProductData,
  SregionSaleRegionsListInfo,
} from '@/types/api'
import type {
  GetProductsReqParams,
  GetProductInfoReqParams,
  GetProductCommentsParams,
  GetProductPromosReqParams,
  GetProductAdditionAndUpgradeParams,
  GetCrossDaysDataParams,
} from '@/types/product'

export const getProducts = async (params: GetProductsReqParams) => {
  const api = useApiClient<NicedayProductListData[]>()
  const result = await api(`/product`, { query: params })

  return result
}

export const getProductsCount = async (params: GetProductsReqParams) => {
  const api = useApiClient<string>()
  const result = await api(`/product-count`, { query: params })

  return result
}

export const getProductInfo = async (pid: string, params: GetProductInfoReqParams) => {
  const api = useApiClient<ProductDetailData>()
  const result = await api(`/product/${pid}/info`, { query: params })

  return result
}

export const getProductComments = async (pid: string, params: GetProductCommentsParams) => {
  const api = useApiClient<FreviewReviewResponse>()
  const result = await api(`/product/${pid}/comments`, { query: params })

  return result
}

export const getProductAdditionAndUpgrade = async (pid: string, params?: GetProductAdditionAndUpgradeParams) => {
  const api = useApiClient<NicedayProductAddUpgData>()
  const result = await api(`/product/${pid}/additionandupgrade`, { query: params })

  return result
}

export const getCrossDaysData = async (pid: string, params?: GetCrossDaysDataParams) => {
  const api = useApiClient<FproductMaxCrossDaysRespData>()
  const result = await api(`/product/${pid}/maxcrossdays`, { query: params })

  return result
}

export const getProductReviewTags = async (id: string) => {
  const api = useApiClient<FproductReviewTagData>()
  const result = await api(`/product/${id}/review-tags`)
  return result
}

export const getProductRemarks = async (pid: string) => {
  const api = useApiClient<FproductRemarkV2Data[]>()
  const result = await api(`/product/${pid}/remarks`)

  return result
}

export const getProductPromos = async (pid: string | number, params: GetProductPromosReqParams) => {
  const api = useApiClient<FpromoListByProductData[]>()
  const { rawObject } = generateSearchParams(params as any)
  const result = await api(`/promo/product/${pid}`, { query: rawObject, headers: { APIVer: '2' } })

  return result
}

export const getProductSaleRegions = async (pid: string) => {
  const api = useApiClient<SregionSaleRegionsListInfo[]>()
  const result = await api(`/product/${pid}/sale-regions`)
  return result
}
